.download-btn {
  padding: 10px !important;
  margin-bottom: 10px;
  text-transform: capitalize !important;

  .btn-label {
    display: inline;
    position: relative;
    top: -2px;
    padding: 15px !important;

    &:hover {
      cursor: pointer;
    }
  }

  .download-icon {
    display: inline;
    vertical-align: text-bottom;
  }
}
