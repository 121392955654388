.youtube-embed {
  height: 505px;
  width: 100%;
}

@media (max-width: 480px) {
  .youtube-embed {
    height: 405px;
  }
}
