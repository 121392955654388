@import "../../../styles/colors.scss";

.accordion {
  box-sizing: border-box;

  &-button {
    border: $bgd-web-app-dark-shade-1;
    background-color: $bgd-web-app-dark-shade-1;
    color: $bgd-website;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;

    &:hover {
      background-color: $bgd-web-app-dark-shade-3 !important;
      color: $bgd-website;
    }

    &:before {
      display: inline-block;
      content: "+";
      margin-right: 20px;
    }

    &[aria-expanded="true"] {
      background-color: $bgd-web-app-dark-shade-3 !important;
    }

    &[aria-expanded="true"]::before,
    &[aria-selected="true"]::before {
      content: "-";
    }
  }

  &-panel {
    padding: 1px 48px;
    animation: fadein 0.35s ease-in;
    background-color: $bgd-web-app-dark-shade-3 !important;
    color: $bgd-web-app-dark-shade-4;
    line-height: 22px;
  }
}

[hidden] {
  display: hidden !important;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.faq-option {
  background-color: $bgd-web-app-dark;
  padding: 10px;

  &:hover {
    background-color: $bgd-web-app-dark-shade-3;
    color: $bgd-website;
    cursor: pointer;
  }
}

.active {
  background-color: $bgd-web-app-dark-shade-3;
  color: $bgd-website;
}

@media (max-width: 480px) {
  .accordion {
    font-size: 14px;

    &-button {
      &:before {
        margin-right: 8px;
      }
    }

    &-panel {
      padding: 1px 10px;
    }
  }
}
