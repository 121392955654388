@import "../../styles/colors.scss";

.card {
  background-color: $bgd-web-app-dark-shade-1 !important;

  &-img {
    height: 200px;
  }

  &-text {
    color: rgba(255, 255, 255, 0.67);
  }

  &-title {
    font-size: 16px !important;
    color: #fff;
  }
  // added media
  @media only screen and (max-width: 1050px) {
    &-title {
      font-size: 1.05rem !important;
    }
  }
}

.pick-title {
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-title {
  white-space: nowrap;
}
