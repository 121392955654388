@import "../../styles/colors.scss";

.active {
  border-bottom: 2px solid $dpd-red;
  color: white;
  font-weight: 400;
  padding-bottom: 10px;
}

.ul {
  display: block;
  padding: 0;
  margin: 0 auto;
  list-style-type: none;
  text-align: center;
  border-bottom: 2px solid rgba(128, 128, 128, 0.2);
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 10px;

  li {
    display: inline-block;
    padding-right: 20px;
    cursor: pointer;
    &:last-child {
      padding-right: 0;
    }

    div {
      margin-bottom: -12px;
      &:first-child {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

.searchLength {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  width: 24px;
  height: 24px;
  float: right;
  font-size: 13px;
  vertical-align: baseline;
  margin-left: 10px;
  padding: 0 !important;
}

.lengthActive {
  background: #dee2e6;
  color: $bgd-web-app-dark-shade-2;
}
