@import "../../styles/colors.scss";

.country-market-info {
  max-height: 50vh;
  overflow-y: auto;

  &-list-item {
    &:hover {
      cursor: pointer;
      color: $dpd-red;
      text-decoration: underline 1px solid $dpd-red;
    }
  }

  &-export-info-btn {
    font-family: sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.3px;
    width: 100%;
  }
}
