@import "../../styles/colors.scss";

.inputContainer {
  padding: 5px;
  border-radius: 4px;
  position: absolute;
  right: 0;
  overflow: hidden !important;
  width: 30px;

  @media (max-width: 992px) {
    display: flex;
    align-items: center;
    top: 50%;
    right: -70px;
    transform: translate(0, -50%);
  }

  @media (max-width: 768px) {
    right: -45px;
  }

  @media (max-width: 555px) {
    right: -25px;
  }

  @media (max-width: 480px) {
    right: -15px;
  }

  @media (max-width: 400px) {
    right: -10px;
  }
}

.inputOpen {
  max-height: 36px;
  overflow: hidden;
  background: white;
  width: 100%;
  transition: width 1s ease-in-out;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;

  @media (max-width: 992px) {
    width: 95%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 555px) {
    width: 85%;
  }

  @media (max-width: 480px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    width: 75%;
  }

  @media (max-width: 320px) {
    width: 70%;
  }
}

.inputClose {
  max-height: 36px;
  overflow: hidden;
  width: 30px;
  transition: width 1s ease-in-out,
    background-color 1s cubic-bezier(0.1, 0.1, 0.1, 0.1);
  -webkit-transition: width 1s ease-in-out,
    background-color 1s cubic-bezier(0.1, 0.1, 0.1, 0.1);
  -moz-transition: width 1s ease-in-out,
    background-color 1s cubic-bezier(0.1, 0.1, 0.1, 0.1);
  -o-transition: width 1s ease-in-out,
    background-color 1s cubic-bezier(0.1, 0.1, 0.1, 0.1);
}

.input {
  box-shadow: none;
  background: none;
  width: 95%;
  outline: none;
  position: relative;

  touch-action: manipulation;
  zoom: 1;

  @media (max-width: 769px) {
    font-size: 14px;
  }

  &:focus {
    background-color: white;
  }
}

.xmark {
  cursor: pointer;
}

.suggestContainer {
  background-color: $bgd-web-app-dark-shade-2;
  position: absolute;
  top: 54px;
  left: -30vw;
  z-index: 50;
  color: white;
  overflow: hidden;
  transition: height 1s ease-in-out;

  @media (max-width: 992px) {
    top: 50px !important;
  }
}

.suggestOpen {
  top: 54px;
  left: -30vw;
  width: 130vw;
}

.suggestClose {
  top: 54px;
  left: -30vw;
  width: 130vw;
  height: 0;
}

.suggestContent {
  position: absolute;
  left: 30vw;
  width: fit-content;
  min-width: 770px;

  @media (max-width: 992px) {
    min-width: initial;
  }

  @media (max-width: 768px) {
    width: 60%;
  }

  @media (max-width: 480px) {
    width: 70%;
  }

  @media (max-width: 390px) {
    width: 80%;
  }
}

.suggest {
  padding-top: 16px;
  padding-bottom: 16px;
}

.headerText {
  color: $bgd-web-app-dark-shade-4;
}

.suggestItem,
.recentItem {
  margin-right: 35px;
  color: white;
  text-decoration: underline;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.3px;

  @media (max-width: 480px) {
    margin-right: 15px;
  }

  @media (max-width: 360px) {
    margin-right: 10px;
  }

  &:hover {
    color: white;
  }
}

.recent {
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.recentItem {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 130px;
  display: inline-block;
}

.clear {
  float: right;
  margin: 0 !important;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: rgba(255, 255, 255, 0.67);
  cursor: pointer;

  @media (max-width: 768px) {
    float: unset;
  }
}
