$accent-orange: #ff9900;
$accent-coral: #fc584c;
$accent-lavender-indigo: #a166ff;
$accent-hot-pink: #ff4f8b;
$accent-mint: #56c0a7;
$accent-red: #dc0032;
$accent-lime: #cafe53;

$bgd-website: #fff;
$bgd-website-shade-1: rgba(255, 255, 255, 0.3);
$bgd-web-app-dark: #141414;
$bgd-web-app-dark-shade-1: #1d2226;
$bgd-web-app-dark-shade-2: #39434f;
$bgd-web-app-dark-shade-3: #383d40;
$bgd-web-app-dark-shade-4: rgba(255, 255, 255, 0.67);

$dpd-red: #dc0032;

$link-color: #3d74d5;

.accent {
  &-orange {
    color: $accent-orange;
  }

  &-coral {
    color: $accent-coral;
  }

  &-lavender-indigo {
    color: $accent-lavender-indigo;
  }

  &-hot-pink {
    color: $accent-hot-pink;
  }

  &-mint {
    color: $accent-mint;
  }

  &-red {
    color: $accent-red;
  }

  &-lime {
    color: $accent-lime;
  }
}

.bg-web-app {
  &-dark {
    color: $bgd-web-app-dark;

    &-shade {
      &-1 {
        color: $bgd-web-app-dark-shade-1;
      }

      &-2 {
        color: $bgd-web-app-dark-shade-2;
      }
    }
  }
}

.bgd-website {
  color: $bgd-website;

  &-shade-1 {
    color: $bgd-website-shade-1;
  }
}

.dpd {
  &-red {
    color: $dpd-red;
  }
}

:export {
  dark: $bgd-web-app-dark;
  darkShade1: $bgd-web-app-dark-shade-1;
  darkShade2: $bgd-web-app-dark-shade-2;
  darkShade3: $bgd-web-app-dark-shade-3;
  darkShade4: $bgd-web-app-dark-shade-4;
  dpdRed: $dpd-red;
  linkColor: $link-color;
}
