.navArticleCard {
  padding: 20px;
  flex-grow: 1;
  justify-content: space-between;
  border-bottom: none !important;
  border-top: none !important;
  width: 90%;
}

.nextArticleCard {
  border-left: 1px solid white !important;
  border-right: none;
}

.emptyCard {
  border: none;
  width: 50vh;
}

.blogButton {
  background-color: #414042 !important;
  border: none !important;
  align-items: center;

  &:focus {
    box-shadow: none !important;
  }

  &:hover {
    background-color: #545355 !important;
    cursor: pointer;
  }
}

.blogBtnText {
  font-size: 16px;
  font-weight: 500;
}
