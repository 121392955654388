@import "../../styles/colors.scss";

.country-delivery-options {
  &-card {
    background-color: $bgd-web-app-dark-shade-2;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-bottom: 20px;

    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0.3px;

    &-service {
      font-size: 20px;
      line-height: 25px;
    }

    &-service-option {
      font-size: 14px;
      line-height: 15px;
    }

    &:hover {
      cursor: pointer;
      border: 1px solid #fff;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
}

.air-classic-icon {
  margin-top: 0.5rem;
}

.service-icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media (max-width: 480px) {
  .country-delivery-options {
    &-card {
      margin-bottom: 10px;

      &-service {
        font-size: 18px;
      }

      &-service-option {
        font-size: 12px;
      }
    }
  }

  .service-icon {
    align-items: flex-start;
  }
}
