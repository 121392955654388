.container {
  position: fixed;
  z-index: 99;
  left: 150px;
  bottom: 50px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .container {
    left: 10px;
  }
}
