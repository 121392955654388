@import "../../styles/colors.scss";

nav.mydpd-secondary-menu {
  background-color: $bgd-web-app-dark-shade-2 !important;
  max-width: 100%;
  height: 53px;
}

.mydpd-header {
  background-color: $bgd-web-app-dark-shade-1;

  .mydpd-navbar-collapse {
    &.show + .mydpd-navbar-right-menu,
    &.collapsing + .mydpd-navbar-right-menu {
      width: 100%;
    }
  }

  .mydpd-nav-user-menu {
    .dropdown-menu {
      min-width: 250px;
    }
  }
}

@media (max-width: 1024px) {
  nav.mydpd-secondary-menu {
    height: 100%;
  }
}

@media (max-width: 480px) {
  nav.mydpd-secondary-menu {
    padding: 0rem !important;
  }
}
