@import "../../styles/colors.scss";

.contact-us-form {
  &-title {
    font-weight: 300;
    font-size: 40px;
    line-height: 49px;
    letter-spacing: 0.3px;
  }

  .close {
    cursor: pointer;
  }
}

.radio-btn {
  display: block;
  cursor: pointer;
  user-select: none;
  text-align: left;

  & + .radio {
    margin-top: 12px;
  }

  input {
    display: none;

    & + span {
      display: inline-block;
      position: relative;
      padding-left: 35px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        left: 0px;
        border-radius: 50%;
        margin-right: 5px;
        width: 16px;
        height: 16px;
        border: 2px solid $bgd-website;
        background: $bgd-web-app-dark-shade-2;
      }

      &:after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background: $dpd-red;
        position: absolute;
        border-radius: 50%;
        top: 6px;
        left: 3px;
        opacity: 0;
        transform: scale(0, 0);
        transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
      }
    }

    &:checked + span:after {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
}
