@keyframes float {
  0% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-50%, -70%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  72% {
    opacity: 1;
  }
}

@keyframes showTopText {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  40%,
  60% {
    transform: translate3d(0, 50%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    transform: translate3d(0, 50%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes showBottomText {
  0% {
    transform: translate3d(0, -100%, 0);
    /* opacity: 0; */
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes lineAnimation {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  50% {
    transform-origin: top;
    transform: scaleY(1);
  }
  51% {
    transform-origin: bottom;
    transform: scaleY(1);
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
}

@keyframes imageAnimation {
  0% {
    transform: translate3d(100%, 0, 0) rotate(90deg) scale(0.5);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 5px 25px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgb(255, 255, 255, 0);
  }
}

@keyframes launcher2Animation {
  0% {
    transform: translate3d(100%, 0, 0);
    display: none;
    opacity: 0;
  }
  100% {
    display: unset;
  }
}

@keyframes launcher3Animation {
  0% {
    transform: translate3d(100%, 100%, 0);
    display: none;
    opacity: 0;
  }
  100% {
    display: unset;
  }
}

@keyframes launcher4Animation {
  0% {
    transform: translate3d(0, 100%, 0);
    display: none;
    opacity: 0;
  }
  100% {
    display: unset;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
  animation-iteration-count: 1;
}

.fadeInImage {
  animation: fadeInImage 4s;
  -webkit-animation: fadeInImage 4s;
  -moz-animation: fadeInImage 4s;
  -o-animation: fadeInImage 4s;
  -ms-animation: fadeInImage 4s;
}

@keyframes fadeInImage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeInImage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInImage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeInImage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeInImage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
