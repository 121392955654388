@import "../../styles//colors.scss";

.country-sidebar-drawer {
  background: $bgd-web-app-dark-shade-1;
  width: 28%;

  &-bg {
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    height: 300px;
  }
}

@media screen and (max-width: 1024px), screen and (max-height: 1100px) {
  .country-sidebar-drawer {
    &-content {
      overflow: auto;
      max-height: 70vh;
    }
  }
}

@media screen and (max-width: 1024px), screen and (max-height: 1000px) {
  .country-sidebar-drawer {
    &-content {
      max-height: 65vh;
    }
  }
}

@media screen and (max-width: 1024px), screen and (max-height: 900px) {
  .country-sidebar-drawer {
    &-content {
      max-height: 60vh;
    }
  }
}

@media screen and (max-width: 768px), screen and (max-height: 768px) {
  .country-sidebar-drawer {
    &-content {
      max-height: 55vh;
    }
  }
}

@media (max-width: 1024px) {
  .country-sidebar-drawer {
    width: 47%;

    &-bg {
      height: 270px;
    }
  }
}

@media (max-width: 480px) {
  .country-sidebar-drawer {
    margin-top: 540px;
    z-index: 1000;
    width: 100%;

    &-bg {
      height: 200px;
    }
  }
}
