.lead-time-results {
  &-container {
    padding: 32px 28px 24px !important;
  }

  &-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
  }

  &-data,
  &-error {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding: 45px 10px;
    text-align: center;
  }

  &-error {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-title,
    &-text {
      letter-spacing: 0.3px;
    }

    &-title {
      font-weight: 370;
      font-size: 24px;
      line-height: 30px;
      margin-top: 20px;
    }

    &-text {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &-data {
    margin-bottom: 25px;

    &-subtext {
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.3px;

      & p:nth-child(2) {
        font-weight: 370;
        margin-bottom: 0;
      }
    }
  }
}
