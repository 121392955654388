.container {
  padding: 0 1.25rem;
}

.navlink {
  font-size: 14px;
  padding-left: 0.5rem;
  margin-right: 1rem;

  &:hover {
    cursor: pointer;
    color: #dc0032;
    text-decoration: underline 1px solid #dc0032;
  }
}

.active {
  color: #dc0032;
  text-decoration: underline 1px solid #dc0032;
}

@media (max-width: 768px) {
  .navlink {
    padding-left: 0.25rem;
    margin-right: 0.5rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0 1rem;
  }

  .navlink {
    padding-left: 0.2rem;
    margin-right: 0.2rem;
  }
}
