@import "../../styles/colors.scss";

.pagination {
  border-bottom: none;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.5);
  padding-left: 0;

  @media (max-width: 992px) {
    padding-top: 25px;
    padding-bottom: 5px;
    justify-content: center;
    padding-left: 0px;
  }

  li {
    display: inline-block;
    padding-right: 10px;
    cursor: pointer;
    &:last-child {
      padding-right: 0;
    }
  }

  a {
    color: rgba(255, 255, 255, 0.67);

    &:hover {
      color: red !important;
      text-decoration: none;
    }
  }
}

.pagination-link-active {
  color: $dpd-red;
}

.previousLabel {
  @media (max-width: 992px) {
    margin-right: auto;
  }
}

.nextLabel {
  @media (max-width: 992px) {
    margin-left: auto;
  }
}
