@import "../../styles/colors.scss";

.link {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.card {
  border: 1px solid black;
  background-color: $bgd-web-app-dark-shade-3;
  border-radius: 4px;
  padding: 10px;
  padding-right: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  padding-left: 15px;
}

.title {
  padding-top: 24px;
  font-size: 18px;
  color: #fff;
  font-weight: 400;
}

.content {
  text-overflow: ellipsis;
  max-height: 90px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: rgba(255, 255, 255, 0.67) !important;
  font-size: 18px;
  font-weight: 400;
  margin-top: 24px;
}
