@import "../../styles/colors.scss";

.get-in-touch-container {
  background-image: url(../../assets/backgrounds/footer-bg.png);
  padding: 4rem 2rem;

  .card {
    background-color: $bgd-web-app-dark-shade-2;
    color: white;
    padding: 25px;
  }

  .body-text {
    white-space: pre-line;
  }
}

@media (max-width: 1022px) {
  .get-in-touch-container {
    .card {
      &-title {
        margin-bottom: 50px;
      }
    }
  }
}

@media (max-width: 535px) {
  .get-in-touch-container {
    .card {
      &-title {
        margin-bottom: 80px;
      }
    }
  }
}

@media (max-width: 480px) {
  .get-in-touch-container {
    .card {
      &-title {
        margin-bottom: 130px;
      }
    }
  }
}

@media (max-width: 360px) {
  .get-in-touch-container {
    .card {
      &-title {
        margin-bottom: 180px;
      }
    }
  }
}
