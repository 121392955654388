@import "../../styles/colors.scss";

.country-market-info {
  &-list-item {
    &:hover {
      cursor: pointer;
      color: $dpd-red;
      text-decoration: underline 1px solid $dpd-red;
    }
  }
}
