.icon {
  color: #fff;
  letter-spacing: 0.3px;
  font-weight: 400;

  &-title {
    line-height: 22px;
    font-size: 22px;
  }

  &-text {
    line-height: 20px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.67);
  }
}
