@import "../../styles/colors.scss";

.leadtime-calc-container {
  color: #fff;
}

.leadtime-form {
  background: $bgd-web-app-dark-shade-2;
  border: 1px solid $bgd-web-app-dark-shade-2;
  border-radius: 4px 0 0 4px;
  padding: 15px 30px !important;

  input {
    background-color: rgba(255, 255, 255, 0.5) !important;
    border-color: #fff;
    color: $bgd-web-app-dark-shade-2;
    font-family: inherit;
    font-size: inherit;
    box-shadow: none;
    border-radius: 4px;

    &:focus {
      background-color: #fff !important;
    }

    &::placeholder {
      color: $bgd-web-app-dark-shade-2 !important;
    }
  }
}

.leadtime-title {
  margin-left: 8px !important;
}

input.leadtime-input {
  padding: 18px;
}
