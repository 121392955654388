@import "../../styles/colors.scss";

.mydpd-header {
  background-color: $bgd-web-app-dark-shade-1;

  .mydpd-navbar-collapse {
    &.show + .mydpd-navbar-right-menu,
    &.collapsing + .mydpd-navbar-right-menu {
      width: 100%;
    }
  }

  .mydpd-nav-user-menu {
    .dropdown-menu {
      min-width: 250px;
    }
  }

  .mydpd-secondary-menu {
    background-color: $bgd-web-app-dark-shade-2;
    max-width: 100%;
  }
}
