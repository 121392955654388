@media (max-width: 480px) {
  .alert-bullet-list {
    svg {
      margin-right: -35px;
    }

    li {
      padding-left: 25px !important;
    }
  }
}

.header-image {
  background-position-y: center;
}
