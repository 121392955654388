.recent-searches {
  &-title {
    color: rgba(255, 255, 255, 0.5);
  }

  &-country {
    padding: 0 20px 0 2px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
