@import "../../styles//colors.scss";

.country {
  &-container {
    width: 500px;
    display: flex;
    flex-wrap: wrap;
    margin: 20px 30px 30px auto;
  }

  &-card {
    width: 30rem;
    background: $bgd-web-app-dark-shade-1 !important;
    color: white !important;
    border-radius: 5px;

    &-content {
      overflow: auto;
    }

    &-input {
      &-bg {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 5px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .country {
    &-container {
      width: 420px;
      margin-right: 6px;
    }
  }
}

@media (max-width: 768px) {
  .country {
    &-container {
      width: 390px;
    }
  }
}

@media (max-width: 480px) {
  .country {
    &-container {
      width: 95%;
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}
