@import "./colors.scss";

.text {
  &-initial {
    text-align: initial !important;
  }

  &-center {
    text-align: center !important;
  }
}

.heading,
.body {
  font-style: normal;
  letter-spacing: 0.3px;
  color: $bgd-website;
  margin: 20px 0;
}

.heading {
  font-weight: 370;

  &-h1 {
    @extend .heading;
    font-size: 56px;
    line-height: 69px;
  }

  &-h2 {
    @extend .heading;
    font-size: 40px;
    line-height: 49px;
  }

  &-h3 {
    @extend .heading;
    font-size: 32px;
    line-height: 40px;
  }

  &-h4 {
    @extend .heading;
    font-size: 24px;
    line-height: 30px;
  }

  &-h5 {
    @extend .heading;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
  }
}

.date,
.header,
.normal {
  margin: 20px 0;
}

.header {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  &-h2 {
    @extend .header;
    font-size: 16px;
    font-weight: 300;
  }
  &-h3 {
    @extend .header;
    font-size: 14px;
  }
}

.date {
  font-size: 12px;
  font-weight: 100;
  color: #dc0032;
}

.normal {
  font-size: 14px;
  font-weight: 200;
  color: #fff;
}

.quotation {
  @extend .normal;
  font-style: italic;
  margin: 60px 0;
}

.BulletPoints {
  color: #e4dfdf;
  font-size: 15px;
  font-weight: 400;
}

.SmallPrint {
  color: #9e9c9c;
  font-size: 10px;
}

.body {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

@media (max-width: 768px) {
  .heading,
  .body {
    margin: 10px 0;
  }

  .heading {
    word-break: break-word;

    &-h1 {
      @extend .heading;
      font-size: 35px;
      line-height: 54px;
    }

    &-h2 {
      @extend .heading;
      font-size: 32px;
      line-height: 44px;
    }

    &-h3 {
      @extend .heading;
      font-size: 26px;
      line-height: 34px;
    }

    &-h4 {
      @extend .heading;
      font-size: 18px;
      line-height: 24px;
    }

    &-h5 {
      @extend .heading;
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.heading-h2 {
  @media (max-width: 360px) {
    font-size: 30px;
    line-height: 42px;
  }
}
