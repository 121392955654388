@import "../../styles/colors.scss";

.mainContent {
  background-color: $bgd-web-app-dark;
  color: white;
  padding-bottom: 3rem;
  min-height: 55vh;
}

.topContainer {
  padding: 0rem 2rem 0rem 2rem;
  background-color: $bgd-web-app-dark-shade-1;
}

.mobileContainer {
  @media (max-width: 768px) {
    padding: 0 15px !important;
  }
}

.heading {
  padding-top: 25px;
  padding-bottom: 16px;
  margin: 0 !important;
}

.subContainer {
  padding-bottom: 30px;
}

.misspelledLink {
  color: white;
  font-weight: bolder;
  text-decoration: underline;

  &:hover {
    color: $dpd-red;
  }
}

.filters {
  padding-top: 45px;
  width: 100%;
  margin-bottom: 3rem;

  display: flex;
  justify-content: space-between;
}

.paginationGroup {
  padding-top: 16px;
  padding-bottom: 10px;

  display: flex;
  justify-content: space-between;

  p {
    width: 50%;
  }
}

.filtersMobile {
  padding-top: 40px;
  padding-bottom: 20px;
}
