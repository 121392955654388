.contact-us-container {
  position: fixed;
  z-index: 99;
  right: 150px;
  bottom: 50px;
}

@media (max-width: 768px) {
  .contact-us-container {
    right: 10px;
  }
}

@media (max-width: 480px) {
  .contact-us-container {
    right: 25px;
  }
}
