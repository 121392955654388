.sources {
  &-container {
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    padding: 1rem;
    margin-top: 1rem;
  }

  &-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    margin-bottom: 0.4rem;
  }

  &-links {
    color: rgba(255, 255, 255, 0.5);
  }
}

@media (max-width: 480px) {
  .sources {
    &-container {
      margin-top: 0.5rem;
    }
  }
}
