@import "../../styles/colors.scss";

.bullet-list {
  color: $bgd-website;
  padding-left: 10px;

  &-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 18px;
  }
}

@media (max-width: 480px) {
  .bullet-list {
    padding-left: 5px;
  }
}
