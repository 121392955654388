.navbar-link {
  text-decoration: none;
  display: inline-block;
  font-weight: 100;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  letter-spacing: 0;
  text-underline-offset: 30%;

  &:hover {
    color: white !important;
    text-decoration: solid underline white 1px !important;
    text-decoration-line: underline;
    text-decoration-color: white !important;
    -webkit-text-decoration-line: underline !important;
    -webkit-text-decoration-color: white !important;
  }
}

.navlink-active {
  color: white !important;
  text-decoration: solid underline white 1px !important;
}

@media (max-width: 480px) {
  .navbar-link {
    font-size: 0.56rem;
    padding-right: 0rem !important;
  }
}
