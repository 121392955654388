.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-top: 40px;
}

.chevron {
  cursor: pointer;
}

.pagination button {
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  margin: 0 0.5rem;
  text-decoration: none;
}

.pagination button:focus {
  box-shadow: none;
}

.pagination button:hover {
  color: #ddd;
}

.pagination .active {
  font-weight: bold;
  color: white;
}
