@import "../../styles/colors.scss";

.country-search-input {
  height: 100%;
  width: 100%;
  background-color: #dee2e6;
  border: inherit;
  border-radius: 5px;
  padding: 20px 60px 20px 30px;
  color: $bgd-web-app-dark-shade-2 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  box-shadow: inset 0px 0px 0px 0px;

  &-btn {
    position: absolute;
    right: 35px;
    top: 20px;
    bottom: 3px;
    z-index: 4;
    cursor: pointer;
  }

  &-label {
    color: $dpd-red;
    margin-left: 5px;
  }
}

@media (max-width: 768px) {
  .country-search-input {
    padding: 20px;

    &-btn {
      right: 25px;
    }
  }
}
