.dark-datepicker {
  background-color: rgba(80, 76, 76, 1) !important;
  border-color: rgba(80, 76, 76, 1);
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  box-shadow: none;
  width: 100%;

  &::placeholder {
    color: #fff;
  }
}
