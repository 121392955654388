.contact-us-form-body {
  &-text {
    font-family: inherit;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.3px;
    margin-bottom: 20px;
  }
}
