@import "~bootstrap/scss/bootstrap";
@import "~normalize.css/normalize.css";
@import "~react-toggle/style.css";
@import "~@dpdgroupuk/mydpd-ui/styles/index";
@import "./styles/colors.scss";
@import "./styles/typography.scss";
@import "./styles/animations.scss";
@import "~animate.css/animate.css";

/* All delay classes will take half the time to start */
:root {
  --animate-delay: 0.5s;
}

* {
  font-family: $pluto;
}

.btn-mint {
  border: 1px solid $accent-mint !important;
}
.btn-mint:hover {
  background-color: $accent-mint !important;
}

html {
  min-height: 100%;
}

input,
textarea,
select {
  font-family: inherit;
  border: none;
}

body {
  background-color: black;
}

// Utility classes
.btn {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  text-transform: capitalize !important;
}

.btn-circle {
  width: 48px;
  height: 48px;
  padding: 0px 0px !important;
  border-radius: 33px !important;
  text-align: center;
}

.btn-outline-primary {
  color: $bgd-website !important;
}

.Underline {
  text-decoration: underline;
}

.Bold {
  font-weight: 800;
}

section {
  padding: 8rem 2rem;
  display: flex;
  background-color: $bgd-web-app-dark;

  &.section-image {
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.annotation {
  @extend .body;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
}

.country-item-active {
  color: $dpd-red;
  text-decoration: underline 1px solid $dpd-red;
}

.country-card-delivery-option-selected {
  border-color: #fff !important;
}

.modal-content {
  border-radius: 6px;
  color: $bgd-website;
  background-color: $bgd-web-app-dark-shade-2;
  border-color: $bgd-web-app-dark-shade-2;
}

.dark-scrollbar {
  &::-webkit-scrollbar {
    background-color: #383d40;
    border-radius: 80px;
  }

  &::-webkit-scrollbar-thumb {
    background: #1d2226;
    border: 3px solid #383d40;
    border-radius: 80px;
  }
}

.country-sidedrawer {
  &-data {
    &-title {
      font-weight: 500 !important;
    }

    &-text {
      color: rgba(255, 255, 255, 0.67) !important;
    }

    &-icon {
      &-title {
        color: #fff;
        font-weight: 400;
        font-size: 22px;
        line-height: 22px;
        letter-spacing: 0.3px;
      }

      &-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.3px;
      }
    }
  }
}

@media (max-width: 768px) {
  section {
    padding: 2rem;
    height: 100% !important;
  }
}

.dark-datepicker-container {
  .react-datepicker {
    font-family: inherit;
    background-color: #e6e7e8;
    color: #fff;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    background: #e6e7e8;
    color: #000;
  }

  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    background: #fff;
    color: #000;
    border-radius: 38px;

    &:hover {
      background-color: $bgd-web-app-dark-shade-1;
      color: #fff;
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected {
    background: #000 !important;
    color: #fff !important;
    font-weight: normal;
    padding-top: 0px;
  }

  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today {
    font-weight: normal;
  }
}

.leadtime-calc-datepicker-container {
  .react-datepicker {
    font-family: inherit;
    background-color: #e6e7e8;
    color: #fff;
    width: 97.5% !important;

    &__month-container {
      width: 97.5% !important;
    }

    &__day-names {
      font-family: "Pluto Sans DPD";
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      text-align: center;

      .react-datepicker__day-name {
        color: rgba(0, 0, 0, 0.38);
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }
}

.row-center {
  justify-content: space-evenly;
}

.article-image {
  max-width: 100%;
}

.button-no-outline {
  background-color: transparent !important;
  border: none;
  outline: none;

  &:focus,
  &:active {
    box-shadow: none !important;
  }
}
.font-roboto {
  font-family: $roboto;
}

.data-dpd-caption-l {
  font-size: 1.125rem;
}
.data-dpd-caption-m {
  font-size: 1rem;
}
.data-dpd-heading-l {
  font-size: 2.25rem;
  gap: var(--spacing-base, 8px);
}
.data-dpd-heading-m {
  font-size: 1.5rem;
}
.data-dpd-heading-s {
  font-size: 1.125rem;
}
.data-dpd-body-l {
  font-size: 1.5rem;
}
.data-dpd-body-m {
  font-size: 1.125rem;
}
.data-dpd-body-s {
  font-size: 1rem;
}

//Burger menu
.bm-burger-button {
  position: relative;
  background-color: rgba(80, 76, 76, 1) !important;
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
}

.bm-cross-button {
  margin: 20px;
  transform: scale(0.75);
}

.bm-menu {
  background: #1d2226;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #1d2226;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

.bm-item {
  display: inline-block;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
