.call-to-action-btn {
  padding: 15px 30px !important;
  margin-bottom: 10px;
  text-transform: capitalize !important;
}

@media (max-width: 768px) {
  .call-to-action-btn {
    padding: 10px !important;
    white-space: normal !important;
  }
}

.green-btn {
  @extend .call-to-action-btn;
  background-color: #134f2f !important ;
  color: white !important ;

  &:hover,
  &:focus,
  &:active {
    background-color: darken(#134f2f, 10%) !important ;
    color: white;
  }
}
