@import "../../styles/colors.scss";

.country-search {
  &-container {
    background-color: $bgd-web-app-dark-shade-2;
    padding: 20px;
    margin-top: -170px;
    border-radius: 5px;
  }

  &-input {
    font-family: inherit;
    border: 0px;
    border-radius: 5px !important;
  }

  &-input-label {
    color: $dpd-red;
  }

  &-button {
    margin-top: 10px;

    button {
      padding: 0px;
    }
  }
}

@media (max-width: 1024px) {
  .country-search {
    &-input {
      font-size: 15px;
    }
  }
}

@media (max-width: 768px) {
  .country-search {
    &-container {
      margin-top: -70px;
    }

    &-form {
      flex-direction: column;
    }
  }
}

@media (max-width: 480px) {
  .country-search {
    &-container {
      padding: 20px 1px;
    }
  }
}
