a.anchor-link {
  font-family: inherit;
  color: inherit;

  &:visited,
  &:hover,
  &:active {
    color: inherit;
  }
}
